<template>
	<div class="Carousel">
		<el-carousel :interval="5000" height="7rem" arrow="always">
			<el-carousel-item v-for="item in Image" :key="item.url">
				<img :src="require('../../assets/home/Carousel/' + item.url)" alt="" />
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				Image: [{
						url: "one.jpeg",
					},
					{
						url: "two.jpg",
					},
					{
						url: "three.jpeg",
					},
					{
						url: "four.jpeg",
					},
					{
						url: "five.jpeg",
					},
				],
			};
		},
	};
</script>

<style lang="scss">
	.Carousel {
		img {
			width: 100%;
		}
	}
</style>
