<template>
	<div id="app">
		<navigationView></navigationView>
		<router-view />
		<bottomView></bottomView>
	</div>
</template>
<script>
	import navigationView from "./components/common/navigationView.vue";
	import bottomView from "./components/common/bottomView.vue";
	export default {
		components: {
			navigationView,
			bottomView,
		},
	};
</script>
<style lang="scss">
	* {
		margin: 0;
		padding: 0;
	}

	#app {
		user-select: none;
	}
</style>
