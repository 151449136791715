<template>
	<div class="icon">
		<div v-for="item in icon" :key="item.name" class="IconImgBox" @click="Jump(item)">
			<img :src="require('../../assets/home/icon/'+item.url)" alt="">
			<p class="Chinese">{{item.name}}</p>
			<p class="English">{{item.EnglishName}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				icon: [{
					url: "City.png",
					path: "/case/City",
					name: "城市展馆",
					EnglishName: 'City Hall'
				}, {
					url: "Cultural.png",
					path: "/case/CultureExpo",
					name: "文博展馆",
					EnglishName: 'Cultural Hall'
				}, {
					url: "Secience.png",
					path: "/case/PopularScience",
					name: "科普展馆",
					EnglishName: 'Secience Hall'
				}, {
					url: "Culture.png",
					path: "/case/CulturalTourism",
					name: "文旅展馆",
					EnglishName: 'Culture & Tourism'
				}]
			}
		},
		mounted() {

		},
		methods: {
			Jump(data) {
				this.$router.push(data.path)
			}
		},
	}
</script>

<style lang="scss" scoped>
	.icon {
		width: 50vw;
		height: 3rem;
		margin: 0 auto;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: space-around;

		.IconImgBox {
			width: 2.5rem;
			text-align: center;
			font-size: 0.15rem;
			cursor: pointer;

			/* 将鼠标样式设置为手型 */
			img {
				width: 0.99rem;
			}

			.Chinese {
				color: #BF2133;
				font-size: 0.22rem;
			}
		}

	}
</style>
