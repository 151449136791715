import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/about',
		name: 'about',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue'),
		// 注册子路由
		children: [
			// 和普通路由一致,path前面没有 '/'
			// 数字孪生首页
			{
				path: "introduce",
				name: "企业简介",
				component: () => import("../views/enterprise/introduceVue.vue")
			},
			{
				path: "course",
				name: "企业历程",
				component: () => import("../views/enterprise/courseVue.vue")
			},
			{
				path: "honor",
				name: "资质荣誉",
				component: () => import("../views/enterprise/honorVue.vue")
			},
			{
				path: "culture",
				name: "企业文化",
				component: () => import("../views/enterprise/cultureVue.vue")
			},
			{
				path: '',
				redirect: "introduce"
			}
		]
	},
	{
		path: '/NewsSection',
		name: 'News',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsSection.vue')
	},
	{
		path: '/newsStorage',
		name: 'newsStorage',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/newsStorage.vue')
	},
	{
		path: '/Technology',
		name: 'Technology',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/TechnologyView.vue'), // 注册子路由
		children: [
			// 和普通路由一致,path前面没有 '/'
			// 数字孪生首页
			{
				path: "medium",
				name: "新媒体技术",
				component: () => import("../views/Technology/mediumVue.vue")
			},
			{
				path: "MixMatch",
				name: "Mix&Match",
				component: () => import("../views/Technology/MixMatch.vue")
			},
			{
				path: "integrationView",
				name: "集成总包一体化",
				component: () => import("../views/Technology/integrationView.vue")
			},
			{
				path: '',
				redirect: "medium"
			}
		]
	},
	{
		path: '/map',
		name: 'map',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/mapView.vue'),
	},
	{
		path: '/case',
		name: 'case',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/caseVue.vue'),
		children: [{
				path: "PopularScience",
				name: "科普馆",
				component: () => import( /* webpackChunkName: "about" */ '../views/caseVue.vue'),
				// component: () => import("../views/case/PopularScience.vue")
			},
			{
				path: "City",
				name: "城市馆",
				component: () => import( /* webpackChunkName: "about" */ '../views/caseVue.vue'),
				// component: () => import("../views/case/CityVue.vue")
			},
			{
				path: "CultureExpo",
				name: "文博馆",
				component: () => import( /* webpackChunkName: "about" */ '../views/caseVue.vue'),
				// component: () => import("../views/case/CultureExpo.vue")
			},
			{
				path: "CulturalTourism",
				name: "文旅馆",
				component: () => import( /* webpackChunkName: "about" */ '../views/caseVue.vue'),
				// component: () => import("../views/case/CulturalTourism.vue")
			}
		]
	},
	{
		path: '/ElementInfo',
		name: 'ElementInfo',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/ElementInfo.vue'),
	}
]
const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
